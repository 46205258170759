import React, { useState, useCallback, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ContractAddress = () => {
  const [copyStatus, setCopyStatus] = useState('');
  const copyStatusRef = useRef(copyStatus); // Use ref to avoid unnecessary renders
  const contractAddress = 'DAMpC91BA8JnaXCVTkACFb4js4dkLfrKr16e53fmpump';

  // Memoize the handleCopy function to avoid recreation on each render
  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(contractAddress);
      copyStatusRef.current = 'Copied to Clipboard!';
      setCopyStatus('Copied to Clipboard!');
      setTimeout(() => {
        copyStatusRef.current = '';
        setCopyStatus(''); 
      }, 2000);
    } catch (err) {
      copyStatusRef.current = 'Failed to copy';
      setCopyStatus('Failed to copy');
      setTimeout(() => {
        copyStatusRef.current = '';
        setCopyStatus(''); 
      }, 2000);
    }
  }, [contractAddress]);

  return (
    <>
      <div className="pt-[6.5rem]">
      <LazyLoadImage
          src="./hero.png"
          alt="hero"
          effect="blur"
          height="auto"
          width="100%"
        />
      </div>
      <div className="bg-[#EEB678] -mb-[4px] -mt-4 flex items-center flex-col justify-center">
        <div className="relative">
          <button
            onClick={handleCopy}
            className="rounded-[35px] mt-8 border-[3px] text-black bg-white border-black px-5 sm:px-7 py-3 sm:py-4 shadow-md hover:bg-gray-100 transition text-sm sm:text-5xl md:text-4xl break-all max-w-[90%] font-bold sm:max-w-none cursor-pointer z-10"
          >
            CA: {contractAddress}
          </button>
          {copyStatus && (
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-black text-white px-3 py-1 rounded-md text-sm">
              {copyStatus}
            </div>
          )}
        </div>
        <span className="pt-14 text-left max-w-prose leading-relaxed sm:leading-relaxed mx-auto hero-h1 text-white text-4xl font-bold sm:text-5xl [text-shadow:_-2px_-2px_0_#000,_2px_-2px_0_#000,_-2px_2px_0_#000,_2px_2px_0_#000] z-10">
          BACKSTORY
        </span>
        <div />
      </div>
    </>
  );
};

export default ContractAddress;
