import React from "react";

const BackStory = () => {
  return (
    <div className="bg-[#EEB678] relative sm:pb-20 pb-0">
      <img src="./CA-img.png" alt="CA-img" className="w-full h-1/3 -mt-[95pxgit ] transform -translate-y-5" loading="lazy" />
      <img
        src="./Backstory-2.png"
        alt="backstory"
        className="absolute sm:top-[68rem] top-[20rem] left-0 h-auto z-5"
        loading="lazy"
      />
    </div>
  );
};

export default BackStory;
